import React, {useEffect, useState} from 'react'
import {renderStartDate} from '../../../utils/renders'
import {useNavigate, useParams} from 'react-router-dom'
import StatusButtonGroup from '../../_archivedComponents/_statusButtonGroup'
import Button from '../../buttons/button/Button'
import ButtonType from '../../buttons/button/logic/models/buttonType'
import {Trans, useTranslation} from 'react-i18next'
import styles from './CandidateDetailsSidePanel.module.css'
import HorizontalDivider from '../../generics/horizontalDivider/HorizontalDivider'
import CandidateScreeningReportDownloadLinkModel
    from '../../molecules/candidateScreeningReportDownloadLink/models/candidateScreeningReportDownloadLinkModel'
import CandidateScreeningReportDownloadLink
    from '../../molecules/candidateScreeningReportDownloadLink/CandidateScreeningReportDownloadLink'
import CandidateScreeningDocumentsDownloadLink
    from '../../molecules/candidateScreeningDocumentsDownloadLink/CandidateScreeningDocumentsDownloadLink'
import CandidateScreeningDocumentsDownloadLinkModel
    from '../../molecules/candidateScreeningDocumentsDownloadLink/models/candidateScreeningDocumentsDownloadLinkModel'
import CandidateScreeningRatingBadge from '../../molecules/candidateScreeningRatingBadge/CandidateScreeningRatingBadge'
import InlineTextLoader from '../../molecules/inlineLoader/InlineTextLoader'
import CandidateSituationProfile from '../../candidateData/candidateProfileInfo/CandidateSituationProfile'
import CandidateSituationProfileArrangementEnum
    from '../../candidateData/candidateProfileInfo/logic/models/candidateSituationProfileArrangementEnum'
import ErrorFragment from './fragments/ErrorFragment'
import CandidateDetailsSidePanelModel from './models/candidateDetailsSidePanelModel'
import Text from '../../typography/text/Text'
import FontSizeEnum from '../../typography/enums/fontSizeEnum'
import ColorEnum from '../../../enums/colorEnum'
import TenantScreeningCard from './components/tenantCard/TenantScreeningCard'
import FontWeightEnum from '../../typography/enums/fontWeightEnum'
import ContactInformation from './components/contactInformation/ContactInformation'

interface CandidateDetailsSidePanelProps {
    readonly model: CandidateDetailsSidePanelModel
    readonly onCloseButtonClick: () => void
    readonly onStatusButtonClick: any // it is a function but because of legacy reasons it's more convenient like this
    readonly onMessageSendButtonClick: () => void
    readonly onNoteCreateButtonClick: () => void
}

export default function CandidateDetailsSidePanel(props: CandidateDetailsSidePanelProps) {
    const params = useParams()
    const navigate = useNavigate()
    const { t } = useTranslation()

    const [model, setModel] = useState(props.model)



    useEffect(() => {
        setModel(props.model)
    }, [props.model])

    function showLegacyStatusButton() {
        return <StatusButtonGroup
            id={model.value.candidateModel.id}
            handleCandidateStatus={(status, idx) => props.onStatusButtonClick(status, idx, true)}
            status={model.value.candidateModel.status}
        />
    }

    async function handleDownloadReportClick(e) {
        if (model.value.isDownloadReportLoading) {
            return
        }
        e.stopPropagation()

        setModel(prev => prev.with({ isDownloadReportLoading: true }))
        await CandidateScreeningReportDownloadLinkModel.downloadReport(model.value.candidateModel)
        setModel(prev => prev.with({ isDownloadReportLoading: false }))
    }

    async function handleDownloadDocumentsClick(e) {
        if (model.value.isDownloadDocumentsLoading) {
            return
        }
        e.stopPropagation()

        setModel(prev => prev.with({ isDownloadDocumentsLoading: true }))
        await CandidateScreeningDocumentsDownloadLinkModel.downloadDocuments(model.value.candidateModel)
        setModel(prev => prev.with({ isDownloadDocumentsLoading: false }))
    }

    function renderScreeningSection() {
        if (model.isScreeningSectionVisible()) {
            return (
                <div className="pt-0 sm:pt-0 p-4 sm:p-6">
                    <HorizontalDivider/>
                    <div className="pt-4 sm:pt-6">
                        <div className={styles.candidateScreeningRating}>
                            <Text
                                size={FontSizeEnum.Md}
                                color={ColorEnum.Gray800}
                                weight={FontWeightEnum.Semibold}
                            >
                                {t('screening')}
                            </Text>
                            {model.value.candidateModel.hasScreeningOutcome() &&
                                <div className={styles.candidateScreeningRatingBadge}>
                                    <CandidateScreeningRatingBadge
                                        model={model.getCandidateScreeningRatingBadgeModel()}
                                    />
                                </div>
                            }
                        </div>

                        {model.value.candidateModel.hasScreeningOutcome() &&
                            <div className={styles.candidateScreeningRatingText}>
                                <Trans
                                    i18nKey={model.getCandidateScreeningRatingBadgeModel().getTooltipText()}
                                    components={{
                                        ul: <ul style={{textAlign: 'left', listStyle: 'initial', marginLeft: 8}}/>,
                                        li: <li/>
                                    }}
                                />
                            </div>
                        }

                        {model.value.candidateModel.hasScreeningOutcome() ?
                            <>
                                <div className={styles.candidateScreeningButtonOptionsContainer}>
                                    {model.value.isDownloadReportLoading ?
                                        <div className="pr-4">
                                            <InlineTextLoader/>
                                        </div>
                                        :
                                        <div
                                            className={styles.candidateScreeningReportDownloadLink}
                                            onClick={handleDownloadReportClick}
                                        >
                                            <CandidateScreeningReportDownloadLink candidateModel={model.value.candidateModel}/>
                                        </div>
                                    }

                                    <li className={styles.candidateScreeningContainerBullet}></li>

                                    {model.value.isDownloadDocumentsLoading ?
                                        <div className="pr-4">
                                            <InlineTextLoader/>
                                        </div>
                                        :
                                        <div onClick={handleDownloadDocumentsClick}>
                                            <CandidateScreeningDocumentsDownloadLink
                                                candidateModel={model.value.candidateModel}
                                            />
                                        </div>
                                    }
                                </div>
                            </> :
                            <div className="pt-4">
                                <Text weight={FontWeightEnum.Regular} size={FontSizeEnum.Sm} color={ColorEnum.Gray400}>{t('candidate.status.screening.report_not_available')}</Text>
                            </div>
                        }

                        {model.value.candidateModel.hasCoTenants &&
                            <div className="pt-4">
                                <div className="pb-2">
                                    <Text weight={FontWeightEnum.Medium} size={FontSizeEnum.Sm} color={ColorEnum.Gray500}>{t('candidate.screening.main_tenant')}</Text>
                                </div>
                                <TenantScreeningCard
                                    fullName={model.value.candidateModel.fullName}
                                    screeningStatus={model.value.candidateModel.screeningRequestStatus}
                                    hideHomeseekerDetails={true}
                                />
                                <div className="pt-4">
                                    <div className="pb-2">
                                        <Text weight={FontWeightEnum.Medium} size={FontSizeEnum.Sm} color={ColorEnum.Gray500}>{t('candidate.screening.co_tenants')}</Text>
                                    </div>
                                    {model.getCoTenantScreeningRequests().map(c => {
                                        return (
                                            <div key={c.value.id} className="[&:not(:last-child)]:pb-4">
                                                <TenantScreeningCard
                                                    fullName={c.value.fullName}
                                                    screeningStatus={c.value.screeningRequestStatus}
                                                    phone={c.value.phoneNumber}
                                                    email={c.value.email}
                                                />
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        }
                    </div>
                </div>
            )
        }
    }

    function renderDetails() {
        const candidate = model.value.candidateModel;
        return model.value.candidateModel ? <div className=" bg-white border-l h-screen">
            <div className="px-2 py-6">
                <div className="flex items-start justify-between">
                    <div className="ml-3 flex min-h-7 justify-between items-center w-full">
                        <h3 className={styles.candidateName}>
                            {t(candidate.getSalutation())} {candidate.firstName}
                        </h3>
                        <div className={styles.rightTop}>
                            <button
                                type="button"
                                className="rounded-md bg-white text-gray-600
                                hover:text-gray-500 focus:outline-none focus:ring-[3px] focus:ring-primary-100 focus:border-primary-300
                                focus:border-[1px]"
                                onClick={() => props.onCloseButtonClick()}
                            >
                                <i className="ri-close-line text-3xl"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div>
                    <div>
                        <div className="px-4 sm:flex sm:items-end sm:px-6">
                            <div className="sm:flex-1">
                                <ContactInformation
                                    birthday={model.value.candidateModel.formatDateAndAge()}
                                    email={model.value.candidateModel.email}
                                    phone={model.value.candidateModel.phone}
                                    employment={model.value.candidateModel.employment}
                                />
                                <div className='py-4'>
                                    {showLegacyStatusButton()}
                                </div>
                                <div>
                                    <div className={styles.informationRejectionMessage}>
                                        <i className="ri-information-line ri-xl"></i>
                                        <p>{t('candidate.popover.rejection.message')}</p>
                                    </div>
                                </div>
                                <HorizontalDivider/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="p-4 sm:p-6">
                    <Text
                        size={FontSizeEnum.Md}
                        color={ColorEnum.Gray800}
                        weight={FontWeightEnum.Semibold}
                    >
                        {t('candidate.popover.title.candidateData')}
                    </Text>
                    <div className={styles.badgeText}>
                        <CandidateSituationProfile
                            model={model.getCandidateSituationProfileModel()}
                            arrangement={CandidateSituationProfileArrangementEnum.ThreeColumns}
                        />
                    </div>
                </div>
                {renderScreeningSection()}
                <div className="px-4 sm:px-6">
                    <HorizontalDivider/>
                </div>
                <section aria-labelledby="notes-title">
                    <div className="bg-white sm:rounded-lg sm:overflow-hidden">
                        <div className="px-4 py-5 sm:px-6 flex flex-row justify-between items-center ">
                            <Text
                                size={FontSizeEnum.Md}
                                color={ColorEnum.Gray800}
                                weight={FontWeightEnum.Semibold}
                            >
                                {t('candidate.popover.title.message')}
                            </Text>

                            <Button
                                title={t('candidate.action.initiateMessage')}
                                buttonType={ButtonType.Primary}
                                icon='ri-mail-line'
                                onClick={() => props.onMessageSendButtonClick()}
                            />
                        </div>
                        <div className="px-4 pb-6 sm:px-6 overflow-y-scroll w-full h-auto whitespace-pre-line ">
                            <ul role="list" className="space-y-8 break-words break-normal">
                                {model.value.candidateModel?.messages?.map((message, index) => (
                                    <li key={index} id="selectedCandidate-message">
                                        <div className="flex space-x-3 border border-gray-100 w-full
                                                py-3 px-3 rounded-lg bg-gray-50">
                                            <div className="flex-shrink-0">
                                                <img
                                                    className="h-10 w-10 rounded-full"
                                                    src={`https://via.placeholder.com/420/cecece/FFFFFF?text=${model.value.candidateModel.firstName.slice(0, 1)}`}
                                                    alt=""
                                                />
                                            </div>
                                            <div className="whitespace-pre-line overflow-hidden break-words break-normal">
                                                <div className="text-base ">
                                                    <p className="font-bold text-gray-900">
                                                        {message.subject}
                                                    </p>
                                                </div>
                                                <div className="mt-1 text-base text-gray-700">
                                                    <div dangerouslySetInnerHTML={{__html: message.body}}/>
                                                </div>
                                                <div className="mt-2 text-sm space-x-2">
                                                    <span className="text-gray-400 font-medium">
                                                        {renderStartDate(message.createdAt)}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </section>
                <div className="px-4 sm:px-6">
                    <HorizontalDivider/>
                </div>
                <section aria-labelledby="timeline-title" className="lg:col-start-3 lg:col-span-1">
                    <div className="pb-6 px-4 sm:px-6">
                        <div className="bg-white sm:rounded-lg sm:overflow-hidden">
                            <div className='py-6 flex flex-row justify-between items-center'>
                                <Text
                                    size={FontSizeEnum.Md}
                                    color={ColorEnum.Gray800}
                                    weight={FontWeightEnum.Semibold}
                                >
                                    {t('candidate.notes.title')}
                                </Text>

                                <Button
                                    title={t('candidate.notes.createNote')}
                                    buttonType={ButtonType.SecondaryGray}
                                    icon='ri-pencil-line'
                                    onClick={() => props.onNoteCreateButtonClick()}
                                />
                            </div>
                            <div className="">
                                <ul role="list" className="space-y-8 ">
                                    {model.value.candidateModel.notes?.length < 1 && <li>
                                        <div className="flex space-x-3">
                                            <div>
                                                <div className="mt-1 text-base text-gray-700">
                                                    <p>{t('candidate.popover.title.placeholder.notesArea')}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </li>}
                                    {model.value.candidateModel.notes?.map((note) => (
                                        <li key={note.id}>
                                            <div className="flex space-x-1 border border-gray-100 w-full
                                                    py-3 px-3 rounded-lg bg-gray-50">
                                                <div className="whitespace-pre-line overflow-hidden break-words break-normal">
                                                    <div className="text-base">
                                                        <p className="font-medium text-gray-900">
                                                            {note.note}
                                                        </p>
                                                        <span className="text-gray-400 font-medium my-3">
                                                            {renderStartDate(note.createdAt)}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div> : <ErrorFragment onCloseButtonClick={props.onCloseButtonClick} />
    }

    return model.value.isCandidateLoading ? <></> : renderDetails()
}
