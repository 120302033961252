import ButtonActionType from './logic/models/buttonActionType';
import styles from './Button.module.css'
import ButtonType from './logic/models/buttonType';

export interface ButtonProps {
    readonly buttonType?: ButtonType
    readonly isDisabled?: boolean
    readonly isHidden?: boolean
    readonly buttonActionType?: ButtonActionType
    readonly icon?: string
    readonly title: string
    readonly isFullWidth?: boolean
    readonly onClick?: () => void
}

export default function Button(props: ButtonProps) {

    function getCurrentStyle() {
        if (props.buttonType === ButtonType.Primary) {
            return styles.primary
        }
        if (props.buttonType === ButtonType.SecondaryGray) {
            return styles.secondaryGray
        }
        return styles.secondary
    }


    return (
        <button
            data-testid="button"
            onClick={() => props.onClick?.()}
            disabled={props.isDisabled}
            type={props.buttonActionType || ButtonActionType.Default}
            className={`
                ${styles.root}
                ${props.isFullWidth && styles.fullWidth}
                ${getCurrentStyle()}
                ${props.isHidden ? 'hidden' : ''}
                flex
            `}
        >
            {props.icon &&
                <i
                    data-testid="icon"
                    className={`
                    ${props.icon}
                    h-5 mr-1`}
                ></i>
            }
            <span data-testid="label">
                {props.title}
            </span>
        </button>
    )
}
