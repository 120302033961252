import styles from './TextArea.module.css'
import ReactQuill from 'react-quill'
import React, {useEffect, useState} from 'react'
import 'react-quill/dist/quill.snow.css';

const H1 = 1;
const H2 = 2;
const H3 = 3;
const H4 = 4;
const p = false; // this value is false to enable the <p> tag. (Basically saying headers = false)

const modules = {
    toolbar: [
        [{ 'header': [H1, H2, H3, H4, p] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
        ['link']
    ],
}

const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link'
]

export interface TextAreaProps {
    readonly value: string
    readonly onChange: (val: string) => void
}
export default function TextArea(props: TextAreaProps) {
    const [editorContent, setEditorContent] = useState<string>('')

    useEffect(() => {
        if (props.value !== editorContent) {
            setEditorContent(props.value)
        }
    }, [props.value])

    const handleChange = (content: string) => {
        setEditorContent(content)
        props.onChange(content)
    }

    return (
        <div className={styles.root} id="textArea">
            <ReactQuill
                className={styles.textArea}
                theme="snow"
                value={editorContent}
                onChange={(val) => handleChange(val)}
                modules={modules}
                formats={formats}
            />
        </div>
    )
}
