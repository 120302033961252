import PhoneNumberLabel from '../../generics/PhoneNumberLabel'
import CandidateModel from '../../../models/candidate/candidateModel'
import styles from './CandidateContactInfo.module.css'
import {useFeature} from '@growthbook/growthbook-react'
import React, {useState,} from 'react'
import CandidateStatusComboBadgeModel
    from '../../molecules/candidateStatusComboBadge/models/candidateStatusComboBadgeModel'
import CandidateStatusComboBadge from '../../molecules/candidateStatusComboBadge/CandidateStatusComboBadge'
import CandidateScreeningRatingBadge from '../../molecules/candidateScreeningRatingBadge/CandidateScreeningRatingBadge'
import CandidateScreeningRatingBadgeModel
    from '../../molecules/candidateScreeningRatingBadge/models/candidateScreeningRatingBadgeModel'
import CandidateStatus from '../../../enums/candidateStatus'
import CandidateScreeningReportDownloadLinkModel
    from '../../molecules/candidateScreeningReportDownloadLink/models/candidateScreeningReportDownloadLinkModel';
import CandidateScreeningReportDownloadLink
    from '../../molecules/candidateScreeningReportDownloadLink/CandidateScreeningReportDownloadLink';
import InlineTextLoader from '../../molecules/inlineLoader/InlineTextLoader';
import { useTranslation } from 'react-i18next'

export interface CandidateInfoMinimalProps {
    readonly model: CandidateModel
}

function CandidateContactInfo(props: CandidateInfoMinimalProps) {
    const FEATURE_FLAG_PROPERIZE_INTEGRATION = useFeature('properize-integration').value

    const [candidateStatusBadgeModel] = useState(CandidateStatusComboBadgeModel.createAccordingToStatusCombo(props.model.getPlace(FEATURE_FLAG_PROPERIZE_INTEGRATION)))
    const [downloadReportIsLoading, setDownloadReportIsLoading] = useState(false)
    const { t } = useTranslation()

    function handleClickDownloadReport(e) {
        e.stopPropagation()

        setDownloadReportIsLoading(true)

        CandidateScreeningReportDownloadLinkModel.downloadReport(props.model).finally(() => {
            setDownloadReportIsLoading(false)
        })
    }

    return (
        <>
            <p className={styles.nameLabel}>
                {t(props.model.getSalutation())} {`${props.model.firstName} ${props.model.lastName ? props.model.lastName : ''}` || '-'}
            </p>
            <PhoneNumberLabel phoneNumber={props.model.phone} />

            {FEATURE_FLAG_PROPERIZE_INTEGRATION && !props.model.isListingTenant && props.model.status === CandidateStatus.Suitable && props.model.screeningRequestRating ?
                <div className={styles.screeningRatingContainer}>
                    <CandidateScreeningRatingBadge
                        model={new CandidateScreeningRatingBadgeModel(props.model.screeningRequestRating, props.model.screeningRequestOutcome)}
                    />
                    {downloadReportIsLoading ?
                        <InlineTextLoader/>
                        :
                        <div onClick={handleClickDownloadReport}>
                            <CandidateScreeningReportDownloadLink candidateModel={props.model}/>
                        </div>
                    }
                </div>
                :
                <CandidateStatusComboBadge model={candidateStatusBadgeModel} />
            }
        </>
    )
}

export default CandidateContactInfo
