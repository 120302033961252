import api from '../../../../utils/api'
import DiscardedLeadJsonToModelConverter from './discardedLeadJsonToModelConverter'
import DiscardedLeadModel from '../model/discardedLeadModel'

export default class DiscardedLeadsPageService {

    public async getAgencyName(agentId: string): Promise<string> {
        const res = await api.get(`api/get-agency/${agentId}`)
        return res.result.name
    }

    public async getDiscardedLeads(agentId: string): Promise<DiscardedLeadModel[]> {
        const result = await api.get(`api/get-discarded-leads/${agentId}`)
        return result.result.map(item => DiscardedLeadJsonToModelConverter.convert(item))
    }
}
