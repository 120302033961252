import api, {request} from '../../../utils/api';
import {START_LOADING, STOP_LOADING} from '../ui/types';


export const getAllListings = (token, params, featureFlagIsEnabled) => async (dispatch) => {
    dispatch({type: START_LOADING});

    // eslint-disable-next-line no-undef
    if (window.REACT_APP_IS_TRANSACTIONAL_LISTING_ENABLED === 'true') {
        let apiRoute = `api/transactional_listings`;
        let dispatchType = 'GET_LISTINGS_OLD'

        if (params.status === 'archived') {
            params.status = ['archived', 'closed'];
        }

        if(featureFlagIsEnabled) {
            apiRoute = `api/transactional-listings-overview`;
            dispatchType = 'GET_LISTINGS'
            if(params.status === 'available') {
                params.status = ['available']
            }
        }

        api.getJsonLd(apiRoute, params)
            .then((response) => {
                dispatch({
                    type: dispatchType,
                    payload: response,
                });
            })
            .catch((e) => {
                dispatch({type: STOP_LOADING});
                console.log(e);
            })
            .finally(() => dispatch({type: STOP_LOADING}));
    } else {
        request(token)
            .get('/api/lead_listings', {params: params})
            .then((response) => {
                dispatch({
                    type: 'GET_LISTINGS',
                    payload: response.data,
                });
            })
            .catch((e) => {
                dispatch({type: STOP_LOADING});
                console.log(e);
            })
            .finally(() => dispatch({type: STOP_LOADING}));
    }
};
