import TopNavigation from '../../../components/navigation/TopNavigation'
import style from './DiscardedLeadsPage.module.css'
import Title from '../../../components/typography/title/Title'
import FontSizeEnum from '../../../components/typography/enums/fontSizeEnum'
import FontWeightEnum from '../../../components/typography/enums/fontWeightEnum'
import ColorEnum from '../../../enums/colorEnum'
import {useTranslation} from 'react-i18next'
import Text from '../../../components/typography/text/Text'
import {useEffect, useRef, useState} from 'react'
import {useParams} from 'react-router-dom'
import Modal from '../../../components/organisms/modal/Modal'
import DiscardedLeadsPageService from './service/discardedLeadsPageService'
import DiscardedLeadsPageModel from './model/discardedLeadsPageModel'

export default function DiscardedLeadsPage() {
    const {t} = useTranslation()
    const {agentId} = useParams()

    const service = useRef(new DiscardedLeadsPageService())

    const [model, setModel] = useState(DiscardedLeadsPageModel.createEmpty())

    useEffect(() => {
        service.current.getAgencyName(agentId)
            .then(val => setModel(prev => prev.with({ agencyName: val })))
        service.current.getDiscardedLeads(agentId)
            .then(val => setModel(prev => prev.with({ discardedLeads: val })))
    }, [])

    function handleTemplateLinkClick(leadIndex: number) {
        setModel(prev => prev.with({
            isModalOpen: true,
            selectedLeadIndex: leadIndex
        }))
    }

    return (
        <div className={style.root}>
            <TopNavigation/>
            <div className={style.content}>
                <Title
                    size={FontSizeEnum.Md}
                    weight={FontWeightEnum.Bold}
                    color={ColorEnum.Gray800}
                >
                    {t('discardedLeadsPage.title')}
                </Title>
                <Title
                    size={FontSizeEnum.Xsm}
                    weight={FontWeightEnum.Regular}
                    color={ColorEnum.Gray500}
                >
                    {model.value.agencyName}
                </Title>


                {model.value.discardedLeads.length > 0 && <table className={style.leadTable} >
                    <thead>
                        <tr>
                            <th>
                                <Text
                                    size={FontSizeEnum.Md}
                                    color={ColorEnum.Gray700}
                                >
                                    {t('discardedLeadsPage.table.lead')}
                                </Text>
                            </th>
                            <th>
                                <Text
                                    size={FontSizeEnum.Md}
                                    color={ColorEnum.Gray700}
                                >
                                    {t('discardedLeadsPage.table.email')}
                                </Text>
                            </th>
                            <th>
                                <Text
                                    size={FontSizeEnum.Md}
                                    color={ColorEnum.Gray700}
                                >
                                    {t('discardedLeadsPage.table.reason')}
                                </Text>
                            </th>

                            <th>
                                <Text
                                    size={FontSizeEnum.Md}
                                    color={ColorEnum.Gray700}
                                >
                                    {t('discardedLeadsPage.table.createdAt')}
                                </Text>
                            </th>

                            <th>
                                <Text
                                    size={FontSizeEnum.Md}
                                    color={ColorEnum.Gray700}
                                >
                                    {t('discardedLeadsPage.table.template')}
                                </Text>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {model.value.discardedLeads.map((row, index) => (
                            <tr key={index}>
                                <td>
                                    <div className={style.tableRowText}>
                                        <Text
                                            size={FontSizeEnum.Md}
                                            color={ColorEnum.Gray700}
                                        >
                                            {row.value.homeseekerName}
                                        </Text>
                                    </div>
                                </td>
                                <td>
                                    <div className={style.tableRowText}>
                                        <Text
                                            size={FontSizeEnum.Md}
                                            color={ColorEnum.Gray700}
                                        >
                                            {row.value.homeseekerEmail}
                                        </Text>
                                    </div>
                                </td>
                                <td>
                                    <div className={style.tableRowText}>
                                        <Text
                                            size={FontSizeEnum.Md}
                                            color={ColorEnum.Gray700}
                                        >
                                            {t(row.getReasonResourceKey())}
                                        </Text>
                                    </div>
                                </td>
                                <td>
                                    <div className={style.tableRowText}>
                                        <Text
                                            size={FontSizeEnum.Md}
                                            color={ColorEnum.Gray700}
                                        >
                                            {row.getFormattedCreatedAt()}
                                        </Text>
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <a
                                            href="#"
                                            onClick={() => handleTemplateLinkClick(index)}
                                        >
                                            <Text
                                                size={FontSizeEnum.Md}
                                                color={ColorEnum.Primary500}
                                            >
                                                {t('discardedLeadsPage.openTemplate')}
                                            </Text>
                                        </a>
                                    </div>
                                </td>
                            </tr>
                        ))}

                    </tbody>
                </table>}

            </div>

            {model.value.discardedLeads.length > 0 &&
                <Modal
                    flowyColor={ColorEnum.White}
                    isOpen={model.value.isModalOpen}
                    onCloseButtonClick={() => setModel(prev => prev.with({ isModalOpen: false }))}
                >
                    <div className={style.modalContent}>
                        <div dangerouslySetInnerHTML={{__html: model.getSelectedLeadStrippedTemplate()}}></div>
                    </div>
                </Modal>
            }
        </div>
    )
}
