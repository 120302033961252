import style from './Modal.module.css'
import ColorEnum, {colorEnumAsCssVar} from '../../../enums/colorEnum'
import FlowyFigure from '../../generics/modalBackgroundIcon/FlowyFigure'
import {useEffect, useRef, useState} from 'react'
import CloseButton from '../../atoms/closeButton/CloseButton'

export interface ModalProps {
    readonly icon?: string
    readonly iconColor?: ColorEnum
    readonly iconBackgroundColor?: ColorEnum
    readonly flowyColor: ColorEnum
    readonly children: React.ReactNode
    readonly isOpen: boolean
    readonly onCloseButtonClick?: () => void
}

export default function Modal(props: ModalProps) {

    const dialogRef = useRef<HTMLDialogElement>()

    const [isOpen, setIsOpen] = useState(props.isOpen)

    useEffect(() => {
        if (props.isOpen) {
            setIsOpen(true)
            dialogRef.current.showModal()
        } else {
            setIsOpen(false)
            setTimeout(() => dialogRef.current.close(), 200)
        }
    }, [props.isOpen])

    function handleClose() {
        setIsOpen(false)
        props.onCloseButtonClick?.()
    }

    return (
        <dialog
            ref={dialogRef}
            className={isOpen ? style.rootVisible : style.root}
            onClose={() => handleClose()}
        >
            <div className="fixed inset-0 w-full z-50">
                <div className="flex min-h-full items-center justify-center">
                    <div className={`${style.mainContainer} ${(isOpen ? style.visible : '')}`}>
                        <div className={style.closeButton}>
                            <CloseButton onClick={() =>props.onCloseButtonClick()} />
                        </div>

                        <FlowyFigure color={props.flowyColor}/>
                        <div className={style.contentContainer}>
                            {props.icon ? <div className={style.iconContainer}>
                                <i
                                    className={style.icon + ' ' + props.icon + ' text-xl'}
                                    style={{
                                        color: colorEnumAsCssVar(props.iconColor),
                                        backgroundColor: colorEnumAsCssVar(props.iconBackgroundColor)
                                    }}
                                ></i>
                            </div> : null}
                            <div className="space-y-2">
                                {props.children}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </dialog>
    )
}
