import { useFormik } from 'formik';
import * as Yup from 'yup';
import 'moment/locale/nl-be';
import BadgesList from '../../badges/BadgesList';
import FormError from '../../banners/FormError';
import {VIEWING_INVITATION_STATUS_TRANSITIONS} from '../../../utils/constants';
import Button from '../../buttons/button/Button';
import ButtonActionType from '../../buttons/button/logic/models/buttonActionType';
import { useTranslation } from 'react-i18next';
import ButtonType from '../../buttons/button/logic/models/buttonType';
import Modal from '../../organisms/modal/Modal';
import ColorEnum from '../../../enums/colorEnum';
import FontSizeEnum from '../../typography/enums/fontSizeEnum';
import FontWeightEnum from '../../typography/enums/fontWeightEnum';
import Text from '../../typography/text/Text';
import TextArea from '../../textArea/TextArea';
import CandidateModel from '../../../models/candidate/candidateModel'

interface InviteToViewingModalProps {
    readonly isOpen: boolean
    readonly closeModal: () => void
    readonly selectedPeople: ReadonlyArray<CandidateModel>
    readonly street: string
    readonly houseNumber: string
    readonly viewingInvitationStatusHandler: (status: string, subject: string, body: string) => void
}
function InviteToViewingModal(props: InviteToViewingModalProps) {

    const { t } = useTranslation()

    function generateSubject(listingStreet: string, listingHouseNumber: string) {
        return `${t('candidate.action.invite.viewing')} ${listingStreet} ${listingHouseNumber}`;
    }

    const formValidationSchema = Yup.object().shape({
        startingTimeslot: Yup.string(),
        endingTimeslot: Yup.string(),
        body: Yup.string().required(),
        subject: Yup.string().required()
    });

    const formik = useFormik({
        initialValues: {
            body: '',
            isListVisible: false,
            subject: generateSubject(props.street, props.houseNumber),
        },
        enableReinitialize: true,
        validationSchema: formValidationSchema,
        onSubmit: () => {
            if (formik.isValid) {
                props.viewingInvitationStatusHandler(
                    VIEWING_INVITATION_STATUS_TRANSITIONS.INVITE,
                    formik.values.subject, formik.values.body
                );
                props.closeModal();
            }
        },
    });

    return (
        <>
            <Modal flowyColor={ColorEnum.Primary100}
                isOpen={props.isOpen}
                onCloseButtonClick={() => props.closeModal()}
            >
                <Text
                    size={FontSizeEnum.Lg}
                    weight={FontWeightEnum.Bold}
                    color={ColorEnum.Gray800}
                >
                    {t('candidate.action.invite.title')}
                </Text>
                <div className="md:w-[705px] overflow-y-auto flex flex-col gap-4">
                    <p className="text-gray-500 sm:text-md">
                        {t('candidate.action.invite.label')}
                    </p>


                    <BadgesList badges={props.selectedPeople.map(person => person.firstName)} maxBeforeDropdown={3}
                        resourcePlural='Kandidaten' resourceSingular='Kandidaat'/>

                    <div>
                        <label className="text-gray-500 sm:text-md">
                            {t('candidate.action.invite.subject')}
                        </label>
                        <input
                            placeholder={generateSubject(props.street, props.houseNumber)}
                            required
                            type="text"
                            value={formik.values.subject}
                            onChange={formik.handleChange}
                            name="subject"
                            id="subject"
                            autoComplete="subject"
                            className="flex-1 block w-full min-w-0 rounded-md rounded-r-md sm:text-md border-gray-300 bg-white"
                        />
                        <FormError message={formik.errors.subject}/>
                    </div>
                    <div
                        className=''
                        data-testid="leadflow-editor"
                    >
                        <TextArea value={formik.values.body} onChange={(e) => formik.setFieldValue('body', e)}/>
                    </div>
                    <FormError message={formik.errors.body}/>
                </div>
                <div className="mt-4 flex justify-end gap-2">
                    <Button
                        title={t('actions.cancel')}
                        onClick={() => props.closeModal()}
                    />
                    <Button
                        buttonType={ButtonType.Primary}
                        onClick={formik.handleSubmit}
                        buttonActionType={ButtonActionType.Submit}
                        title={t('viewing.invitation.send')}
                        icon="ri-mail-send-line mr-1"
                    />
                </div>
            </Modal>
        </>

    );
}

export default InviteToViewingModal;
